import * as React from 'react';
import { DetailMain } from 'components/organisms/detailMain';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import RangeSlide from 'components/organisms/rangeSlide';
import GallerySlide, { ContentsObject } from 'components/organisms/gallerySlide';
import GalleryLoopSlide, { GalleryLoopSlideType } from 'components/organisms/galleryLoopSlide';
import { DetailContent, ImgsObject } from 'components/organisms/detailContent';
import { ProjectDetailNumbers, NumbersObject } from 'components/organisms/projectDetailNumbers';
import { ProjectDetailOtherData, CreditObject } from 'components/organisms/projectDetailOtherData';
import { ContentInner } from 'components/organisms/contentInner';
import { ProjectCard } from 'components/molecules/projectCard';
import { FullImg } from 'components/molecules/fullImg';
import { FullVideo } from 'components/molecules/fullVideo';
import { Youtube } from 'components/molecules/youtube';
import { MediaGrid } from 'components/molecules/mediaGrid';
import { TextSet } from 'components/molecules/textSet';
import { JaHeading } from 'components/atoms/jaHeading';
import { Text } from 'components/atoms/text';
import { ProjectsDetailContext } from '../../gatsby-node/createProjectsDetailPages';
import { ProjectsDetailEnQuery } from 'types/graphql-types';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import shuffle from 'lodash/shuffle';
import { uniqBy, difference } from 'lodash';
import SEO from 'util/seo';
import { AwardProps } from 'components/atoms/award';
import { TopicsSectionWrap } from 'components/molecules/topicsSectionWrap';
import Img from 'components/atoms/img/';
import { Video } from 'components/atoms/video';
import { ProjectsBackToWrapBottom } from 'components/organisms/projectsBackToWrapBottom';
import { List } from 'components/atoms/list';
import styled from '@emotion/styled';
import { mq } from 'style/variables';
import sizes from 'style/sizes';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema

interface IndexPageProps {
  pageContext: ProjectsDetailContext;
  data: ProjectsDetailEnQuery;
}

const ProjectListWrap = styled.div`
  margin-top: ${sizes.margin[177]};
  margin-bottom: ${sizes.margin[177]};
  ${mq.onlysp} {
    margin-top: 108px;
    margin-bottom: 108px;
  }
`;

const Pages: React.FC<IndexPageProps> = ({ data }) => {
  const { otherProjects } = data;
  const acf = data.shifterProjectsData?.acf;
  // const title = data.shifterProjectsData?.title;
  const currentService = acf?.services?.map(service => service?.post_title);

  const sameClientProjects = otherProjects.edges.filter(
    project => project.node.acf?.client_name?.en === acf?.client_name?.en
  );
  const sameServiceProjects = shuffle(
    uniqBy(
      otherProjects.edges
        .filter(project => project.node.acf?.client_name?.en !== acf?.client_name?.en)
        .filter(project => project?.node.acf?.services?.some(service => currentService?.includes(service?.post_title))),
      'node.id'
    )
  );
  const remainingProjects = difference(otherProjects.edges, [...sameClientProjects, ...sameServiceProjects]);
  const showOtherProjects = [...sameClientProjects, ...sameServiceProjects, ...remainingProjects].slice(0, 12);

  return (
    <>
      <SEO title={`${acf?.headline_en}｜Projects`} en />
      <Helmet
        bodyAttributes={{
          'data-page': 'project-detail',
        }}
      />
      <DetailMain
        imgpath={[acf?.kv_sp || `noimage/img-card-kv-sp.jpg`, acf?.kv || `noimage/img-card-kv.jpg`]}
        alt={''}
        client={acf?.client_abbr?.en || undefined}
        work={acf?.product?.en || ''}
        outlines={(acf?.outline as string[]) || undefined}
        overviewText={acf?.overview_text?.en || ''}
        visitUrl={acf?.url || undefined}
        isShowSolutionArea
      >
        <JaHeading size={'middle'} dangerouslySetInnerHTML={{ __html: acf?.headline_en || 'No title' }} />
      </DetailMain>

      {acf?.modules?.map((module, idx) => {
        if (
          module?.type === 'feature' &&
          module?.feature?.headline_en &&
          module?.feature?.pics &&
          module?.feature?.pics?.length > 0
        ) {
          return (
            <DetailContent
              key={`module-${idx}`}
              imgs={
                module.feature?.pics?.map(pic => {
                  return {
                    path: pic?.pic || `noimage/img-card-thumb.jpg`,
                    alt: '',
                  };
                }) as ImgsObject[]
              }
              isImgRight={module?.feature?.img_right || false}
            >
              <JaHeading
                size={'middle'}
                dangerouslySetInnerHTML={{
                  __html: module?.feature?.headline_en || 'NO TITLE',
                }}
              />
              <Text
                isDiv
                dangerouslySetInnerHTML={{
                  __html: module?.feature?.text_en || 'NO TEXT',
                }}
              />
            </DetailContent>
          );
        }

        if (module?.type === 'list_set' && (module?.list_set?.type === 'ol' || module.list_set?.type === 'ul')) {
          const { ul, ol } = module?.list_set;
          return (
            <ContentInner key={`module-${idx}`} spPadding>
              <ProjectListWrap>
                {ul && <List note txts={ul?.map(listItem => listItem?.li_en) as string[]}></List>}
                {ol && <List note ordered txts={ol?.map(listItem => listItem?.li_en) as string[]}></List>}
              </ProjectListWrap>
            </ContentInner>
          );
        }

        if (module?.type === 'basic_text' && module?.basic_text?.headline_en) {
          return (
            <ContentInner key={`module-${idx}`} spPadding>
              <TextSet>
                <JaHeading
                  size={'middle'}
                  dangerouslySetInnerHTML={{ __html: module?.basic_text.headline_en || 'No title' }}
                />
                <Text isDiv dangerouslySetInnerHTML={{ __html: module?.basic_text.text_en || 'No title' }} />
              </TextSet>
            </ContentInner>
          );
        }

        if (module?.type === 'pic_double' && module?.pic_double && module?.pic_double.pic1) {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <MediaGrid
                imgs={[
                  {
                    path: module?.pic_double.pic1 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_double.pic2 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                ]}
              />
            </ContentWrapper>
          );
        }

        if (module?.type === 'pic_triple' && module?.pic_triple && module?.pic_triple.pic1) {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <MediaGrid
                imgs={[
                  {
                    path: module?.pic_triple.pic1 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 1,
                  },
                  {
                    path: module?.pic_triple.pic2 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_triple.pic3 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                ]}
              />
            </ContentWrapper>
          );
        }

        if (module?.type === 'pic_sextuple' && module?.pic_sextuple && module?.pic_sextuple.pic1) {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <MediaGrid
                imgs={[
                  {
                    path: module?.pic_sextuple.pic1 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 1,
                  },
                  {
                    path: module?.pic_sextuple.pic2 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 1,
                  },
                  {
                    path: module?.pic_sextuple.pic3 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_sextuple.pic4 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_sextuple.pic5 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_sextuple.pic6 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                ]}
              />
            </ContentWrapper>
          );
        }

        if (module?.type === 'pic_carousel') {
          const srcs = module.pic_carousel?.visuals?.map(item => {
            return {
              path: item?.visual || `noimage/img-card-thumb.jpg`,
              media: item?.visual?.includes('.mp4') || item?.visual?.includes('.mov') ? 'video' : 'image',
              title: item?.caption,
            };
          });

          if (!srcs) return null;

          return (
            <GallerySlide
              key={`module-${idx}`}
              type={module.pic_carousel?.size === 'large' ? 'full' : undefined}
              contents={srcs as ContentsObject[]}
            />
          );
        }

        if (module?.type === 'pic_large' && module?.pic_large) {
          return <FullImg key={`module-${idx}`} imgpath={module?.pic_large || `noimage/img-card-thumb.jpg`} alt={''} />;
        }

        if (module?.type === 'video_large' && module?.video_large) {
          return (
            <FullVideo
              url={
                module?.video_large ||
                'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
              }
            />
          );
        }

        if (module?.type === 'youtube') {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <TopicsSectionWrap>
                {module.youtube?.size === 'large' ? (
                  <Youtube id={module?.youtube.alternative_id || 'V86xKCqVsx0'} />
                ) : module.youtube?.size === 'small' ? (
                  <ContentInner>
                    <Youtube id={module?.youtube.alternative_id || 'V86xKCqVsx0'} />
                  </ContentInner>
                ) : null}
              </TopicsSectionWrap>
            </ContentWrapper>
          );
        }

        if (module?.type === 'visual_set') {
          if (module.visual_set?.size === 'large') {
            return (
              <ContentWrapper key={`module-${idx}`}>
                <TopicsSectionWrap>
                  {module?.visual_set?.visual?.includes('.mp4') || module?.visual_set?.visual?.includes('.mov') ? (
                    <Video
                      url={
                        module?.visual_set.visual ||
                        'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                      }
                    />
                  ) : (
                    <Img
                      filename={module?.visual_set.visual || ''}
                      alt={''}
                      caption={module?.visual_set.caption || undefined}
                    />
                  )}
                </TopicsSectionWrap>
              </ContentWrapper>
            );
          }
          if (module.visual_set?.size === 'small') {
            return (
              <ContentWrapper key={`module-${idx}`}>
                <TopicsSectionWrap>
                  <ContentInner>
                    {module?.visual_set?.visual?.includes('.mp4') || module?.visual_set?.visual?.includes('.mov') ? (
                      <Video
                        url={
                          module?.visual_set.visual ||
                          'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                        }
                      />
                    ) : (
                      <Img
                        filename={module?.visual_set.visual || ''}
                        alt={''}
                        caption={module?.visual_set.caption || undefined}
                      />
                    )}
                  </ContentInner>
                </TopicsSectionWrap>
              </ContentWrapper>
            );
          }
        }

        if (module?.type === 'pic_slider') {
          const srcs: GalleryLoopSlideType[] | undefined = module.pic_slider?.pics?.map(item => {
            return {
              path: item?.pic || `noimage/img-card-thumb.jpg`,
              media: item?.pic?.includes('.mp4') || item?.pic?.includes('.mov') ? 'video' : 'image',
            };
          });
          if (!srcs) return null;

          return (
            <GalleryLoopSlide
              key={`module-${idx}`}
              type={module.pic_slider?.size === 'large' ? 'full' : undefined}
              rtl={module.pic_slider?.rtl || undefined}
              contents={srcs}
            />
          );
        }

        if (module?.type === 'appeal') {
          const numbers = module?.appeal?.fact?.map(item => {
            return {
              title: item?.headline || 'No Title',
              number: item?.number || 'No Number',
              unit: item?.unit || null,
              isPlus: item?.change === '+',
              isMinus: item?.change === '-',
            };
          }) as NumbersObject[];

          return (
            <ProjectDetailNumbers
              key={`module-${idx}`}
              title={module.appeal?.label || 'No Title'}
              numbers={numbers}
              numbersCol={module.appeal?.layout === 'col1' ? 1 : 2}
            >
              <JaHeading
                size={'middle'}
                dangerouslySetInnerHTML={{ __html: module?.appeal?.headline_en || 'No title' }}
              />
              <Text isDiv dangerouslySetInnerHTML={{ __html: module?.appeal?.text_en || 'No Text' }} />
            </ProjectDetailNumbers>
          );
        }

        return null;
      })}

      <ProjectDetailOtherData
        client={acf?.client_abbr?.en ? acf?.client_name?.en || undefined : undefined}
        credit={acf?.credit?.map(credit => {
          return { title: credit?.role, data: credit?.member } as CreditObject;
        })}
        partner={acf?.credit_ptn?.map(creditPtn => {
          return {
            title: creditPtn?.role,
            data: creditPtn?.member,
          } as CreditObject;
        })}
        awards={
          (acf?.awards?.map(award => {
            return {
              award: award?.award ? award?.award[0]?.post_title : 'No Title',
              win: award?.prize,
              to: award?.url,
            };
          }) as AwardProps[]) || undefined
        }
      />

      {showOtherProjects.length > 0 && (
        <RangeSlide perView={3} title={'Other Projects'} isplacedbottom>
          {showOtherProjects.map(project => (
            <ProjectCard
              key={project.node?.id}
              size={'small'}
              imgpath={project?.node?.acf?.thumb || `noimage/img-card-thumb.jpg`}
              title={project?.node?.acf?.headline_en || 'NO TITLE'}
              client={project?.node?.acf?.client_abbr?.en || undefined}
              work={project?.node?.acf?.product?.en || undefined}
              tags={project?.node?.acf?.outline as string[]}
              to={`/en/projects/${project?.node?.slug}`}
              isEn
            />
          ))}
        </RangeSlide>
      )}
      <ProjectsBackToWrapBottom to={'/en/projects'}></ProjectsBackToWrapBottom>
      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query ProjectsDetailEn($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    otherProjects: allShifterProjectsData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } }, slug: { ne: $slug } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            headline_en
            services {
              post_title
            }
            thumb
            client_name {
              en
              ja
            }
            client_abbr {
              en
              ja
            }
            outline
          }
        }
      }
    }
    shifterProjectsData(slug: { eq: $slug }) {
      acf {
        awards {
          award {
            post_title
          }
          prize
          url
        }
        client_abbr {
          en
          ja
        }
        client_name {
          en
          ja
        }
        credit {
          member
          role
        }
        credit_ptn {
          member
          role
        }
        headline_en
        kv
        kv_sp
        launch
        modules {
          type
          appeal {
            fact {
              change
              number
              headline
              unit
            }
            text_en
            text
            layout
            label
            headline_en
            headline
          }
          basic_text {
            headline
            headline_en
            text
            text_en
          }
          feature {
            pics {
              pic
            }
            headline
            headline_en
            img_right
            text
            text_en
          }
          pic_carousel {
            visuals {
              visual
              caption
            }
            size
          }
          pic_slider {
            pics {
              pic
            }
            rtl
            size
          }
          pic_double {
            pic1
            pic2
          }
          pic_triple {
            pic1
            pic2
            pic3
          }
          pic_sextuple {
            pic2
            pic1
            pic3
            pic4
            pic5
            pic6
          }
          visual_set {
            size
            visual
            caption
          }
          pic_large
          video_large
          youtube {
            size
            alternative_id
          }
          list_set {
            ol {
              li_en
            }
            ul {
              li_en
            }
            type
          }
        }
        overview_text {
          ja
          en
        }
        product {
          ja
          en
        }
        services {
          post_title
        }
        studios {
          post_title
        }
        url
        top_show_area
        thumb
        outline
      }
      slug
      title {
        rendered
      }
    }
  }
`;
